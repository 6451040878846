.header {
    background-color: rgb(250, 250, 250);
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: 0px;
    padding: 0px;
    position: fixed;
    box-shadow: 0 0.46875rem 2.1875rem rgba(4, 9, 20, 0.03), 0 0.9375rem 1.40625rem rgba(4, 9, 20, 0.03), 0 0.25rem 0.53125rem rgba(4, 9, 20, 0.05), 0 0.125rem 0.1875rem rgba(4, 9, 20, 0.03);
}

.nav-button {
    width: 77.5%;
    margin-left: 15px;
    transition: all 0.3s;
    overflow: hidden;
    /* white-space: nowrap; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.nav-button.collapsed {
    width: 90%;
}